<template>
  <Skills msg="Welcome to Your Vue.js App" />
</template>

<script>
// @ is an alias to /src
import Skills from '@/components/Skills.vue'

export default {
  name: 'skills',
  components: {
    Skills
  }
}
</script>
