<template>
  <WorkHistory />
</template>

<script>
// @ is an alias to /src
import WorkHistory from '@/components/WorkHistory.vue'

export default {
  name: 'workHistory',
  components: {
    WorkHistory
  }
}
</script>
