<template>
  <About msg="Welcome to Your Vue.js App" />
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue'

export default {
  name: 'home',
  components: {
    About
  }
}
</script>
